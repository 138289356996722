import React from 'react'

import { isBrowser } from 'utils'

const vhFix = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

// TODO: Debounce or throttle
if (isBrowser) {
  window.addEventListener('resize', vhFix)
  vhFix()
}

const Provider = ({ children }) => {
  return <>{children}</>
}

export default Provider

export const wrapRootElement = ({ element }) => {
  return <Provider>{element}</Provider>
}
