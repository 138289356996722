import 'whatwg-fetch'
import 'promise-polyfill/src/polyfill'
import 'unfetch/polyfill'
import 'abortcontroller-polyfill'
import 'url-search-params-polyfill'

import Cookies from 'js-cookie'
import loadScript from 'simple-load-script'

import { TRACKING_COOKIE } from './src/utils'

import 'styles/index.css'

const cookie = Cookies.get(TRACKING_COOKIE)
const trackingAllowed = cookie !== undefined && cookie === 'true'

export { wrapRootElement } from 'components/Provider'

export const onClientEntry = () => {
  if (!trackingAllowed) {
    return
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

  loadScript('https://www.googletagmanager.com/gtm.js?id=GTM-MHJ5TKR')
}

export const onRouteUpdate = ({ location }) => {
  setTimeout(() => {
    if (!trackingAllowed) {
      return
    }
    window.dataLayer.push({
      event: 'pageView',
      path: location.href,
    })
  }, 50)
}
