module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Besucherliste.at: Corona Gästeliste Online Tool für Österreich","short_name":"Besucherliste.at","description":"Die digitale Gästeliste für Lokale in Österreich: Verordnungskonform (Wien, Niederösterreich, Salzburg, Oberösterreich und Tirol) und sofort einsetzbar! In Deutsch und Englisch!","start_url":"/","lang":"de","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6a05811c74e60d92f5bacef8ddff7b28"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
